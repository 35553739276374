import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { GA4EventName, GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type Filter, type Sorting } from '../../../../shared/types/search';
import { type SearchReducerActionType } from '../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../types/searchReducer';
import { FilterSidePanelBody, FilterSidePanelFooter } from '../../Filter/FilterSidePanel';
import styles from '../MapViewModal.module.scss';

type FilterSidePanelProps = {
    readonly showLocationFilter: boolean;
    readonly isSidePanelOpen: boolean;
    readonly filter: Filter;
    readonly locale: string;
    readonly nbItems: number;
    readonly filterChanged: boolean;
    readonly shouldReset: boolean;
    readonly isInitialLocation: boolean;
    readonly isInitialPrice: boolean;
    readonly sorting?: Sorting;
    readonly attributes?: FacetFilter[];
    readonly geoLocationError?: GeolocationPositionError;
    readonly limit?: number;
    readonly className?: string;
    readonly onFilterChange: (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ) => void;
    readonly resetFilter: () => void;
    readonly setFilterPanelOpen: (filterPanelOpen: boolean) => void;
    readonly isModalView?: boolean;
};

const FilterSidePanel = ({
    attributes,
    className,
    filter,
    filterChanged,
    geoLocationError,
    isInitialLocation,
    isInitialPrice,
    isModalView,
    isSidePanelOpen,
    limit,
    locale,
    nbItems,
    onFilterChange,
    resetFilter,
    setFilterPanelOpen,
    shouldReset,
    showLocationFilter,
    sorting,
}: FilterSidePanelProps): JSX.Element => {
    const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(false);

    useEffect(() => {
        setIsFilterPanelVisible(true);
    }, []);

    const closeSidePannels = () => {
        setIsFilterPanelVisible(false);
        setTimeout(() => {
            setFilterPanelOpen(false);
        }, 400);
    };

    const closeFilterPanel = (): void => {
        trackFilterInteraction('Close', undefined, {
            eventName: GA4EventName.ClickButton,
            click_element: 'Filterbox close',
            click_text: '<',
            list_type: GA4FilterListType.MapView,
        });
        closeSidePannels();
    };

    const onSortingChangeHandler = (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ) => {
        onFilterChange(type, value, name);
        closeSidePannels();
    };

    return (
        <div
            className={classNames(
                styles.filterPanel,
                'position-absolute start-0 top-0 bottom-0 overflow-hidden pb-2-5x',
                isSidePanelOpen && isFilterPanelVisible ? styles.panelOpen : styles.panelClosed,
                className,
            )}
            data-testid="filter-side-panel"
        >
            <FilterSidePanelFooter
                filter={filter}
                locale={locale}
                itemCount={nbItems}
                hasFilterChanged={filterChanged}
                resetFilter={resetFilter}
                closeFilterPanel={closeFilterPanel}
                className={classNames(styles.filterPanelFooter, 'position-relative')}
                isModalView
                listType={GA4FilterListType.MapView}
            />

            <FilterSidePanelBody
                sorting={sorting}
                filter={filter}
                showLocationFilter={showLocationFilter}
                shouldReset={shouldReset}
                isInitialLocation={isInitialLocation}
                isInitialPrice={isInitialPrice}
                onFilterChange={onFilterChange}
                onSortingChange={onSortingChangeHandler}
                geoLocationError={geoLocationError}
                facets={attributes}
                paginationLimit={limit}
                className={classNames(styles.filterPanelBody, 'h-100 overflow-scroll')}
                isModalView={isModalView}
            />
        </div>
    );
};

export { FilterSidePanel };
